// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    free: false,
    apiBaseUrl: 'https://nesya-api-dev.tenang.ai',
    apiUrl: 'https://nesya-api-dev.tenang.ai/v1',
    socketUrl: 'https://nesya-chat-engine-dev.tenang.ai',
    // socketUrl: 'http://localhost:3001',
    chatEngineUrl: 'https://nesya-chat-engine-dev.tenang.ai/v1',
    // chatEngineUrl: 'http://localhost:3001/v1',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    VITE_HUME_API_KEY: '5D2G9RsHzpwvoOdW1UzxDhonCGAOM7P3kfsIAQpGiVLZBpws',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    VITE_HUME_CLIENT_SECRET: '8qwgyH1ljeKvjhbCEjNLSe8jRmOGYxvsGvJjAXg0G7gPF6ekNYWetW1JkHoMELRG',
    humeConfigId: 'eed77124-b3b3-47ad-8a3f-4079e56ba34a',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
